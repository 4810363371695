<template>
  <div class="container">
    <p class="text-left">アンケート実施の前に以下動画を視聴してください（約7分）。動画を視聴し終わると「次へ」ボタンが表示されます。</p>
    <YoutubeComponent @ready="onReady" @watched="onWatched"></YoutubeComponent>
    <div class="pt-3" />
    <ul class="nav mt-3" v-if="watched">
      <li><button type="button" class="btn_next btn btn-primary" @click="onClickButton">次へ</button></li>
    </ul>
    <ul class="nav mt-3">
      <li><button type="button" class="btn_next btn btn-secondary" @click="back">戻る</button></li>
    </ul>
    <p class="text-left mt-5">試料採取方法の詳細は以下から確認できます（任意）</p>
    <YoutubeComponent v-if="viewFlg" :id="video2" @watched="onWatched"></YoutubeComponent>
  </div>
</template>
<script>
import { Mixin } from '@/mixin.js'
import { checkStatus } from '@/utils/common.js'
import { config } from '@/constants.js'
import YoutubeComponent from '@/components/Youtube'

export default {
  mixins: [Mixin],
  components: { YoutubeComponent },
  data() {
    return {
      watched: false,
      video2: config.VIDEO_ID2,
      viewFlg: false,
    }
  },
  async created() {
    // ステータスチェック
    await checkStatus(this, 2)
  },
  methods: {
    onReady() {
      this.viewFlg = true
    },
    onWatched(watched) {
      this.watched = watched
    },
    onClickButton() {
      if (this.watched) {
        // 事前アンケート画面へ
        this.$router.push({name: 'ConsentForm'})
      }
    },
  },
}
</script>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
